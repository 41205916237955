<template>
  <rs-side-panel ref="sidePanel">
    <template slot="header">Müşteriye Ürün Ekle</template>
    <template slot="body">
      <p>
        <strong>Dikkat:</strong> Seçilen müşterilerin var olan ürünü varsa
        sonlandırılacaktır.
      </p>
      <v-form
        @submit.prevent="handleFormSubmit"
        ref="form"
        :filled="isLoading"
        :readonly="isLoading"
      >
        <v-row cols="12" dense>
          <v-col sm="6" v-if="!id">
            <rs-select-customer
              v-model="formData.customer_ids"
              :rules="[rules.required]"
              @change:item="handleCustomerSelected"
              multiple
            >
            </rs-select-customer>
          </v-col>

          <v-col sm="6" v-else>
            <rs-select-customer
              v-model="formData.customer_id"
              :rules="[rules.required]"
              disabled
              :clearable="false"
            >
            </rs-select-customer>
          </v-col>

          <v-col sm="6">
            <rs-select-product
              label="Ürün Seçin"
              v-model="formData.product_id"
              :rules="[rules.required]"
              @change:item="handleProductSelected"
              :disabled="id"
              :clearable="!id"
            >
            </rs-select-product>
          </v-col>

          <v-col cols="12" sm="6">
            <rs-money-field
              :label="
                formData.is_yearly ? 'Yıllık Tutar / BB' : 'Aylık Tutar / BB'
              "
              v-model="formData.amount"
              :rules="[
                rules.required,
                rules.min(formData.amount, 0.0),
                rules.max(formData.amount, 999999.99),
              ]"
              :disabled="isLoading"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-switch
              :label="formData.is_yearly ? 'Yıllık' : 'Aylık'"
              v-model="formData.is_yearly"
              :rules="[rules.required]"
              @change="handleIsYearlyChanged"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-datepicker
              label="Başlangıç Tarihi"
              v-model="formData.starts_on"
              :rules="[rules.required]"
              :disabled="formData.is_active"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <rs-datepicker
              label="Bitiş Tarihi"
              v-model="formData.ends_on"
              :disabled="!formData.starts_on"
              :rules="
                !formData.starts_on
                  ? []
                  : [
                      rules.required,
                      rules.min(formData.ends_on, formData.starts_on),
                    ]
              "
              :min="formData.starts_on"
              hint="Doldurulursa paket bu tarihten sonra sonlanmış sayılır"
              persistent-hint
            />
          </v-col>
        </v-row>

        <rs-form-buttons
          @cancel="handleCancelClick"
          :is-loading="isLoading"
          hide-submit
          @submit="handleFormSubmit"
        />
      </v-form>
    </template>
  </rs-side-panel>
</template>

<script>
import { hasForm, hasPermissions } from "@/view/mixins";

export default {
  mixins: [hasForm, hasPermissions],
  data() {
    return {
      id: null,
      selectedProduct: null,
      formData: {
        customer_id: null,
        customer_ids: [],
        product_id: null,
        starts_on: new Date().toJSON().split("T")[0],
        ends_on: null,
        amount: null,
        is_yearly: false,
      },
    };
  },
  methods: {
    show(customerId, productId, id) {
      this.id = null;
      this.formData = {
        customer_id: null,
        customer_ids: [],
        product_id: null,
        starts_on: new Date().toJSON().split("T")[0],
        ends_on: null,
        amount: null,
        is_yearly: false,
      };

      if (productId) {
        this.formData.product_id = productId;
      }

      if (customerId) {
        this.formData.customer_ids = [customerId];
      }

      if (id) {
        this.id = id;
        this.load();
      }

      this.$nextTick(() => {
        this.$refs.sidePanel.show();
      });
    },
    load() {
      this.isLoading = true;

      return this.$api
        .query(`api-super-admin/customer-products/${this.id}`)
        .then((response) => {
          this.loadData(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleFormSubmit() {
      if (this.isLoading || !this.$refs.form.validate()) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      this.isLoading = true;
      const formData = Object.assign({}, this.formData);

      if (this.id) {
        this.$api
          .put(`api-super-admin/customer-products/${this.id}`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        if (formData.customer_id) {
          delete formData.customer_ids;
        } else {
          delete formData.customer_id;
        }

        this.$api
          .post(`api-super-admin/customer-products`, formData)
          .then(() => {
            this.$toast.success("Kaydedildi");
            this.$emit("saved");
            this.hide();
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    handleProductSelected(product) {
      this.selectedProduct = product;

      if (this.formData.is_yearly) {
        this.formData.amount = this.selectedProduct.yearly_price_per_house;
      } else {
        this.formData.amount = this.selectedProduct.monthly_price_per_house;
      }
    },
    handleIsYearlyChanged(isYearly) {
      if (this.selectedProduct === null) {
        return;
      }

      if (isYearly) {
        this.formData.amount = this.selectedProduct.yearly_price_per_house;
      } else {
        this.formData.amount = this.selectedProduct.monthly_price_per_house;
      }
    },
    handleCancelClick() {
      this.hide();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
