export default [
  {
    path: "/logs/action-logs",
    name: "logs.action.list",
    component: () => import("@/view/pages/logs/ActionLogList.vue"),
  },
  {
    path: "/logs/action-logs/:id",
    name: "logs.action.show",
    props: (route) => ({ id: route.params.id, disabled: true }),
    component: () => import("@/view/pages/logs/ActionLogForm.vue"),
  },
  {
    path: "/logs/rs-updates",
    name: "logs.app-updates",
    component: () => import("@/view/pages/logs/AppUpdates.vue"),
  },
];
