var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',_vm._b({attrs:{"headers":_vm.headersShown,"items":_vm.list,"loading":_vm.isLoading,"options":_vm.options,"server-items-length":_vm.total,"items-per-page":_vm.itemsPerPage,"show-select":false},on:{"update:options":function($event){_vm.options=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":[function($event){_vm.itemsPerPage=$event},_vm.handleItemsPerPageUpdated]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('p',[_vm._v(" Varsayılan olarak aktif ürünler listelenir. Pasif ürünleri listelemek için filtreyi temizleyin. ")]),_c('CustomerProductForm',{ref:"customerProductForm",on:{"saved":_vm.loadList}}),(_vm.$refs.headSearch)?_c('rs-table-title-bar',{staticClass:"mt-6",attrs:{"title":"","icon":"","search":_vm.search.query,"add-route":"#","hide-edit":""},on:{"clear-filters":_vm.$refs.headSearch.reset,"reload":_vm.loadList,"update:search":function($event){return _vm.$set(_vm.search, "query", $event)},"click:add":_vm.handleAddClick}}):_vm._e()]},proxy:true},{key:"body.prepend",fn:function({ headers }){return [_c('rs-table-head-search',{ref:"headSearch",attrs:{"headers":headers,"search":_vm.search},on:{"update:search":function($event){_vm.search=$event}}})]}},{key:"item.edit",fn:function({ item }){return [_c('a',{staticClass:"btn btn-icon btn-sm btn-clean",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleEditClick(item)}}},[_c('i',{staticClass:"menu-icon mdi mdi-pencil"})])]}},{key:"item.customer_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
        name: 'admin.crm.customers.edit',
        params: {
          id: item.customer_id,
        },
      }}},[_vm._v(" "+_vm._s(item.customer_name)+" ")])]}},{key:"item.product_name",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
        name: 'admin.products.edit',
        params: {
          id: item.product_id,
        },
      }}},[_vm._v(" "+_vm._s(item.product_name)+" ")])]}},{key:"item.is_yearly",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.is_active",fn:function({ value }){return [_c('rs-table-cell-boolean',{attrs:{"value":value}})]}},{key:"item.amount",fn:function({ value }){return [_c('rs-table-cell-number',{attrs:{"price":"","value":value}})]}},{key:"item.starts_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.ends_on",fn:function({ value }){return [_c('rs-table-cell-date',{attrs:{"value":value}})]}},{key:"item.actions",fn:function({ item }){return [_c('rs-table-button-delete',{attrs:{"confirmed":"","loading":_vm.isLoading},on:{"deleted":function($event){return _vm.handleDeleteClick(item)}}})]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},'v-data-table',_vm.dataTableAttrs,false))
}
var staticRenderFns = []

export { render, staticRenderFns }