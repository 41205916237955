// @ts-check

const VERSION_KEY = "rs-v";

export const getVersion = () => {
  const version = window.localStorage.getItem(VERSION_KEY);

  if (version !== null) {
    return parseInt(version, 10);
  }

  return null;
};

export const setVersion = (version) => {
  window.localStorage.setItem(VERSION_KEY, version);
};

export default {
  getVersion,
  setVersion,
};
