<template>
  <v-data-table
    v-bind="dataTableAttrs"
    :headers="headersShown"
    :items="list"
    :loading="isLoading"
    :options.sync="options"
    :server-items-length="total"
    :items-per-page.sync="itemsPerPage"
    v-model="selectedItems"
    @update:items-per-page="handleItemsPerPageUpdated"
    :show-select="false"
  >
    <template v-slot:top>
      <CustomerClusterForm ref="customerClusterForm" @saved="loadList" />
      <rs-table-title-bar
        class="mt-6"
        v-if="$refs.headSearch"
        title=""
        icon=""
        @clear-filters="$refs.headSearch.reset"
        @reload="loadList"
        :search.sync="search.query"
        hide-edit
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:body.prepend="{ headers }">
      <rs-table-head-search
        :headers="headers"
        :search.sync="search"
        ref="headSearch"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.user_name="{ item }">
      <router-link
        :to="{
          name: 'admin.users.edit',
          params: {
            id: item.user_id,
          },
        }"
      >
        {{ item.user_name }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.resident_name="{ item }">
      <router-link
        :to="{
          name: 'definitions.residents.edit',
          params: {
            id: item.resident_id,
          },
        }"
      >
        {{ item.resident_name }}
      </router-link>
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.is_active="{ value }">
      <rs-table-cell-boolean :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.cluster_is_active="{ value }">
      <rs-table-cell-boolean :value="value" />
    </template>

    <!-- eslint-disable-next-line -->
    <template v-slot:item.created_at="{ value }">
      <rs-table-cell-date show-time :value="value" />
    </template>
  </v-data-table>
</template>

<script>
import { debounce } from "@/core/plugins/debounce";
import { hasDataTable } from "@/view/mixins";

export default {
  name: "AdminAdvertClickList",
  mixins: [hasDataTable],
  props: {
    advertId: {
      type: Number,
      required: false,
    },
  },
  watch: {
    advertId() {
      this.loadList();
    },
    search: {
      handler: debounce(function () {
        this.loadList();
      }),
      deep: true,
    },
    options: {
      handler() {
        this.loadList();
      },
      deep: true,
    },
  },
  data() {
    return {
      options: {
        sortBy: ["created_at"],
        sortDesc: [true],
      },
      search: {
        is_active: true,
      },
      headers: [
        {
          text: "Kullanıcı",
          value: "user_name",
          searchable: "text",
        },
        {
          text: "Sakin",
          value: "resident_name",
          searchable: "text",
        },
        {
          text: "Tıklama",
          value: "created_at",
          searchable: "date",
        },
      ],
    };
  },
  methods: {
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];

      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.advertId) {
        params.advert_id = this.advertId;
      }

      return this.$api
        .query("api-super-admin/advert-clicks", {
          params,
        })
        .then((response) => {
          this.loadListFromResponse(response);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.loadList();
    });
  },
};
</script>
