// @ts-check

import VersionService from "../version.service";

export const UPDATE_VERSION = "updateVersion";
export const HAS_NEW_VERSION = "hasNewVersion";

export const SET_VERSION = "setVersion";
export const SET_NEW_VERSION = "setNewVersion";

const state = {
  version: VersionService.getVersion(),
  newVersion: false,
};

const getters = {
  version(state) {
    return state.version;
  },
  newVersion(state) {
    return state.newVersion;
  },
};

const actions = {
  [UPDATE_VERSION](context, version) {
    context.commit(SET_VERSION, version);
  },
  [HAS_NEW_VERSION](context, newVersion) {
    context.commit(SET_NEW_VERSION, newVersion);
  },
};

const mutations = {
  [SET_VERSION](state, version) {
    state.version = version;
    VersionService.setVersion(version);
  },
  [SET_NEW_VERSION](state, newVersion) {
    state.newVersion = newVersion;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
